import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import axios from 'axios'
import urlDetector from './i18nUrlDetector'
const languageDetector = new LanguageDetector()
languageDetector.addDetector(urlDetector)

const backendOptions = {
  // eslint-disable-next-line no-undef
  loadPath: `${process.env.REACT_APP_CDN_URL}/uploads/locales/{{lng}}/{{ns}}.json`,
  crossDomain: true,

  // allow credentials on cross domain requests
  withCredentials: false,

  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: false,

  requestOptions: {
    // used for fetch, can also be a function (payload) => ({ method: 'GET' })
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  },

  reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(languageDetector)
  .init(
    {
      // resources,
      // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      fallbackLng: 'en',
      defaultLng: 'en',
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: backendOptions,
      detection: {
        order: [
          'querystring',
          'cookie',
          'localStorage',
          'sessionStorage',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
      defaultNS: 'translation',
      ns: 'translation',
      fallbackNS: 'translation',
      react: {
        wait: true,
        useSuspense: false,
      },
      saveMissing: false,
      nsSeparator: false,
      keySeparator: false,
    },
    (err) => {
      //eslint-disable-next-line
      if (err) return console.log('something went wrong loading', err)

      axios.defaults.headers.common['Accept-Language'] = i18n.language
    }
  )

export default i18n
