export const SET_LANGUAGES = 'SET_LANGUAGES'
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const SET_SERVICES = 'SET_SERVICES'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_API_ERRORS = 'SET_API_ERRORS'
export const SET_API_SUCCESS = 'SET_API_SUCCESS'
export const SET_SHOW_MODAL_FORM = 'SET_SHOW_MODAL_FORM'
export const SET_APP_LANGUAGES = 'SET_APP_LANGUAGES'
export const ADD_IMAGES = 'ADD_IMAGES'
export const DELETE_IMAGES = 'DELETE_IMAGES'
export const SET_MENU = 'SET_MENU'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_UNREAD_NOTIFICATIONS_COUNT = 'SET_UNREAD_NOTIFICATIONS_COUNT'
export const SET_TOKEN = 'SET_TOKEN'

export const ADD_PACKAGES = 'ADD_PACKAGES'
export const DELETE_PACKAGES = 'DELETE_PACKAGES'
export const SET_PACKAGES = 'SET_PACKAGES'

export const ADD_FORM_CONFIG = 'ADD FORM CONFIG'
export const DELETE_FORM_CONFIG = 'DELETE_FORM_CONFIG'

export const ADD_ORDER_SERVICES = 'ADD_OREDER_SERVICES'
export const DELETE_ORDER_SERVICES = 'DELETE_ORDER_SERVICES'
export const CLEAR_ORDER_SERVICES = 'CLEAR_ORDER_SERVICES'
export const SET_ORDER_SERVICES = 'SET__ORDER_SERVICES'

export const SET_ORDER = 'SET_ORDER'
export const DELETE_ORDER = 'DELETE_ORDER'

export const COPY_ORDER_SERVICE = 'COPY_ORDER_SERVICE'

export const SET_CHECKED_TABLE = 'SET_CHECKED_TABLE'
export const SET_CHECKED_TABLE_ALL = 'SET_CHECKED_TABLE_ALL'
export const SET_INPUT_DATA = 'SET_INPUT_DATA'

export const SET_OPEN_FILTERS = 'SET_OPEN_FILTERS'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_ASSIGN_FILTER = 'SET_ASSIGN_FILTER'
export const CLEAR_TABLE = 'CLEAR_TABLE'
export const SET_MODAL_DATA_SHOW = 'SET_MODAL_DATA_SHOW'
export const SET_MODAL_DATA_URL = 'SET_MODAL_DATA_URL'
export const SET_RELOAD = 'SET_RELOAD'
export const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL'
export const SET_DEFAULT_FILTERS = 'SET_DEFAULT_FILTERS'

export const CLEAR_FORM = 'CLEAR_FORM'
export const SET_FORM_DATA = 'SET_FORM_DATA'

export const SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT'

export const SET_CHAT_TAGS = 'SET_CHAT_TAGS'

export const SET_NAVIGATION_HISTORY = 'SET_NAVIGATION_HISTORY'
